import { z } from 'zod';
import { MailingAddressSchema } from './geo';
import { CollectibleSchema } from './collectible';
import { EventsSchema } from './events';
import { ActionSchema, ConversionSchema } from './conversions';
import { SaleSchema } from './sale';
import { AttributeSchema } from './inventory';
import { LoyaltyBalanceSchema, LoyaltyTemplateSchema, ViewableRedemptionSchema } from './loyalty';

export const SightingSchema = z.object({
	source: z.string().optional(),
	timestamp: z.number().optional(),
	lat: z.number().optional(),
	long: z.number().optional(),
});

export const CompanySchema = z.object({
	id: z.string().optional(),
	name: z.string().optional(),
	city: z.string(),
	country: z.string(),
	zipCode: z.string(),
	state: z.string(),
	street: z.string(),
	raw: z.string().nullable(),
	lat: z.number(),
	long: z.number(),
});

export const SignupURLSchema = z.object({
	url: z.string().optional(),
	ts: z.number().optional(),
});

export const CustomMacroSchema = z.object({
	key: z.string().optional(),
	value: z.string().optional(),
});

export const ReferenceSchema = z.object({
	contactID: z.string().optional(),
	firstSeen: z.number().optional(),
});

export const RecommendationSchema = z.object({
	sku: z.string().optional(),
	productName: z.string().optional(),
	brand: z.string().optional(),
	cat: z.string().optional(),
	dsku: z.boolean().optional(),
	dname: z.boolean().optional(),
	dbrand: z.boolean().optional(),
	dcat: z.boolean().optional(),
	rsale: z.boolean().optional(),
});

export const DynamicContentRecommendationsSchema = z.object({
	brands: z.array(z.string()).optional(),
	categories: z.array(z.string()).optional(),
	productNames: z.array(z.string()).optional(),
});

export const MergedContactSchema = z.object({
	fn: z.string().optional(), // FirstName
	ln: z.string().optional(), // LastName
	pl: z.string().optional(), // Platform
	cn: z.string().optional(), // ContactID
	sr: z.string().optional(), // SrcID
	hph: z.string().optional(), // HomePhone
	eph: z.string().optional(), // MobilePhone
	em: z.string().optional(), // Email
	md: z.string().optional(), // MedicalID
	dl: z.string().optional(), // DriversLicense
	mk: z.string().optional(), // MatchKey
	rg: z.string().optional(), // SyncRegion
	eci: z.string().optional(), // ExternalCustomerID
	cid: z.string().optional(), // CustomIdentifier
	fs: z.number().optional(), // FirstSeen
	cty: z.number().optional(), // CustomerType
	dm: z.boolean().optional(), // DirectMailable
	lts: z.number().optional(), // LoyaltySignupTS
});

export const CustomerAudienceSchema = z.object({
	id: z.string().optional(),
	name: z.string().optional(),
	avatar: z.string().optional(),
	lastTouch: z.number().optional(),
});

export const ContactSchema = z.object({
	addr: MailingAddressSchema.optional(),
	geoip: MailingAddressSchema.nullable(),
	geoSightings: z.array(SightingSchema).nullable(),
	company: CompanySchema.nullable(),
	ifa: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	email: z.string(),
	secondaryEmail: z.string(),
	homePhone: z.string(),
	mobilePhone: z.string(),
	twitter: z.string(),
	avatar: z.string(),
	matchKey: z.string(),
	website: z.string(),
	platform: z.string(),
	favoriteStore: z.string(),
	meta: z.string(),
	ltID: z.string(),
	srcID: z.string(),
	timezone: z.string(),
	browser: z.string(),
	ip: z.string(),
	contactID: z.string(),
	gender: z.string(),
	mcpr: z.string(),
	mcc: z.string(),
	mcsc: z.string(),
	mcb: z.string(),
	mcs: z.string(),
	refs: z.array(ReferenceSchema).nullable(),
	otherPhone: z.string().nullable(),
	customAttributes: z.array(AttributeSchema),
	sales: z.array(SaleSchema).nullable(),
	conversions: z.array(ConversionSchema).nullable(),
	actions: z.array(ActionSchema).nullable(),
	pixelEvents: z.array(EventsSchema).nullable(),
	signupURLs: z.array(SignupURLSchema),
	brandIDs: z.array(z.string()),
	companyIDs: z.array(z.string()),
	rcmdts: z.array(RecommendationSchema).nullable(),
	rcmdtsV2: z.array(RecommendationSchema).nullable(),
	dynRcmdts: DynamicContentRecommendationsSchema.nullable(),
	merged: z.array(z.string()).nullable(),
	pcIDs: z.array(z.string()).nullable(),
	medID: z.string().nullable(),
	externalMatchID: z.string().nullable(),
	externalCustomerID: z.string().nullable(),
	customID: z.string().nullable(),
	syncRegion: z.string().nullable(),
	storeTimezone: z.string().nullable(),
	macros: z.array(CustomMacroSchema).nullable(),
	mrgV2: z.array(MergedContactSchema).nullable(),
	favoriteShopName: z.string().nullable(),
	legacyLoyaltyPoints: z.array(LoyaltyBalanceSchema).nullable(),
	loyaltyBalances: z.array(LoyaltyBalanceSchema).nullable(),
	collectibles: z.array(CollectibleSchema).nullable(),
	refCode: z.string().nullable(),
	leakyBucket: z.string().nullable(),
	driversLicense: z.string().nullable(),
	favoriteStoreID: z.number().int().nullable(),
	optreqTime: z.number().int().nullable(),
	brin: z.number().int(),
	brout: z.number().int(),
	pushOptIn: z.number().int().nullable(),
	pushOptOut: z.number().int().nullable(),
	smsconsent: z.number().int().nullable(),
	emailconsent: z.number().int().nullable(),
	tosconsent: z.number().int().nullable(),
	optinTime: z.number().int().nullable(),
	emailOptOutTime: z.number().int().nullable(),
	references: z.number().int().nullable(),
	favoriteShopID: z.number().int().nullable(),
	lsTS: z.number().int().nullable(),
	medIDExpiration: z.number().int().nullable(),
	customerType: z.number().int().nullable(),
	emailOptInTime: z.number().int().nullable(),
	optoutTime: z.number().int().nullable(),
	vOptinTime: z.number().int().nullable(),
	vOptoutTime: z.number().int().nullable(),
	piic: z.number(),
	inserted: z.number().int(),
	birthdate: z.number().int(),
	avgCart: z.number(),
	avgCheckout: z.number(),
	firstSale: z.number().int(),
	spent: z.number(),
	rev: z.number(),
	tls: z.number(),
	daysBetween: z.number(),
	signedUp: z.number().int(),
	loyaltyPoints: z.number(),
	usedLoyaltyPoints: z.number(),
	loyaltySignupTS: z.number().int(),
	overrideLoyaltyTS: z.number().int().nullable(),
	seenfirst: z.number().int(),
	lastEmail: z.number().int(),
	lastModified: z.number().int(),
	expectedReturn: z.number().int(),
	emailoptin: z.boolean().nullable(),
	acceptedTerms: z.boolean(),
	sendOptIn: z.boolean().nullable(),
	emailOptOut: z.boolean().nullable(),
	hasRequested: z.boolean().nullable(),
	isMedical: z.boolean().nullable(),
	smsoptout: z.boolean().nullable(),
	loyalty: z.boolean(),
	optOut: z.boolean(),
	smsoptin: z.boolean(),
	likelyReturn: z.boolean(),
	ageGate: z.boolean(),
	hasSaleAttr: z.boolean(),
	isDefault: z.boolean(),
});

export const RawContactSchema = ContactSchema.extend({
	pointsDate: z.number().optional(),
	addr_street: z.string().optional(),
	addr_city: z.string().optional(),
	addr_state: z.string().optional(),
	addr_zipcode: z.string().optional(),
	addr_country: z.string().optional(),
	overridesignupdate: z.number().optional(),
});

export const FullContactSchema = ContactSchema.extend({
	audiences: z.array(CustomerAudienceSchema).nullable(),
	referCode: z.string().nullable(),
	referLink: z.string().nullable(),
	wallet: z.string().nullable(),
	redemptions: z.array(ViewableRedemptionSchema).nullable(),
	uiEmailOptOutTS: z.number().int().nullable(),
	uiBrowserOptInTS: z.number().int().nullable(),
	uiPushOptInTS: z.number().int().nullable(),
	lastSeen: z.number().int().nullable(),
	uiSMSOptInTS: z.number().int().nullable(),
	uiVoiceOptInTS: z.number().int().nullable(),
	uiTransactionalOptOutTime: z.number().int().nullable(),
	appInstalledTS: z.number().int().nullable(),
	appLastOpenTS: z.number().int().nullable(),
	appTotalOpens: z.number().int().nullable(),
	uiBrowserOptIn: z.boolean().nullable(),
	uiPushOptIn: z.boolean().nullable(),
	uiSMSOptIn: z.boolean().nullable(),
	uiEmailOptOut: z.boolean().nullable(),
	uiVoiceOptIn: z.boolean().nullable(),
});

// What is submitted when signing up a new loyalty member
export const LoyaltyContactSchema = ContactSchema.extend({
	settings: LoyaltyTemplateSchema,
	address: z.string(),
	addressOptions: MailingAddressSchema,
	driversLicenseVal: z.string(),
	signature: z.string(),
	referrer: z.string(),
	customInputs: z.record(z.string()),
	disabledSMS: z.boolean(),
	refreshSMSOptIn: z.boolean(),
	refreshEmailOptIn: z.boolean(),
	signupFormID: z.string(),
	signupFormStoreID: z.number(),
});

export const TrimmedContactSchema = ContactSchema.pick({
	srcID: true,
	contactID: true,
	avatar: true,
	platform: true,
	inserted: true,
	firstName: true,
	lastName: true,
	email: true,
	secondaryEmail: true,
	homePhone: true,
	mobilePhone: true,
	usedLoyaltyPoints: true,
	loyaltyPoints: true,
	loyalty: true,
	loyaltySignupTS: true,
	leakyBucket: true,
	favoriteStoreID: true,
	sendOptIn: true,
	optinTime: true,
	optoutTime: true,
	optreqTime: true,
	emailOptOutTime: true,
	emailOptInTime: true,
	smsconsent: true,
	emailconsent: true,
	tosconsent: true,
	matchKey: true,
	driversLicense: true,
	medID: true,
	medIDExpiration: true,
	customerType: true,
	syncRegion: true,
	birthdate: true,
	externalCustomerID: true,
});

export const PaginatedContactsSchema = z.object({
	results: z.array(TrimmedContactSchema).optional(),
	total: z.number().optional(),
	start: z.number().optional(),
	limit: z.number().optional(),
	size: z.number().optional(),
	totalPersonas: z.number().optional(),
});

export const OverrideContactSchema = z.object({
	address: MailingAddressSchema.optional(),
	loyalty: z.boolean().optional(),
	os: z.record(z.number()).optional(),
	homePhone: z.string().optional(),
	mobilePhone: z.string().optional(),
	email: z.string().optional(),
	driversLicense: z.string().optional(),
	lastName: z.string().optional(),
	firstName: z.string().optional(),
	favoriteStoreID: z.number().optional(),
	birthdate: z.number().optional(),
	ts: z.number().optional(),
	loyaltyTs: z.number().optional(),
	hasPassedAgeGate: z.boolean().optional(),
	optOut: z.boolean().optional(),
});

export const OverrideContactWithIDSchema = OverrideContactSchema.extend({
	id: z.string(),
});

export const SortDirectionSchema = z.enum(['asc', 'desc']);

export const SortFieldSchema = z.object({
	direction: SortDirectionSchema,
	priority: z.number().optional(),
});

export const SortFieldsSchema = z.object({
	name: SortFieldSchema.optional(),
	firstName: SortFieldSchema.optional(),
	lastName: SortFieldSchema.optional(),
	signupDate: SortFieldSchema.optional(),
	points: SortFieldSchema.optional(),
	email: SortFieldSchema.optional(),
	phone: SortFieldSchema.optional(),
	inserted: SortFieldSchema.optional(),
	lifetimeSpend: SortFieldSchema.optional(),
	favoriteStoreID: SortFieldSchema.optional(),
	lastModified: SortFieldSchema.optional(),
	lastSale: SortFieldSchema.optional(),
	sales: SortFieldSchema.optional(),
	merges: SortFieldSchema.optional(),
});

export const FieldComparisonSchema = z.object({
	name: z.string(),
	value1: z.any(),
	value2: z.any(),
	direction: SortDirectionSchema,
	priority: z.number(),
});

export const PaginationSchema = z.object({
	page: z.number(),
	pageSize: z.number(),
	startIndex: z.number(),
	endIndex: z.number(),
	resultsCount: z.number(),
	resultsPages: z.number(),
	totalCount: z.number(),
	totalPages: z.number(),
});

export const ContactQuerySchema = z.object({
	search: z.string().optional(),
	name: z.string().optional(),
	firstName: z.string().optional(),
	lastName: z.string().optional(),
	email: z.string().optional(),
	phone: z.string().optional(),
	address: MailingAddressSchema.optional(),
	medicalID: z.string().optional(),
	driversLicense: z.string().optional(),
	minDOB: z.number().optional(),
	maxDOB: z.number().optional(),
	favoriteStoreIDs: z.array(z.string()).optional(),
	platforms: z.array(z.string()).optional(),
	minLoyaltySpent: z.number().optional(),
	maxLoyaltySpent: z.number().optional(),
	minLifetimeSpend: z.number().optional(),
	maxLifetimeSpend: z.number().optional(),
	minAvgCartItems: z.number().optional(),
	maxAvgCartItems: z.number().optional(),
	minAvgCheckout: z.number().optional(),
	maxAvgCheckout: z.number().optional(),
	minTotalLoyaltySpend: z.number().optional(),
	maxTotalLoyaltySpend: z.number().optional(),
	minTotalRevenue: z.number().optional(),
	maxTotalRevenue: z.number().optional(),
	minSales: z.number().optional(),
	maxSales: z.number().optional(),
	minPoints: z.number().optional(),
	maxPoints: z.number().optional(),
	firstSeenStart: z.number().optional(),
	firstSeenEnd: z.number().optional(),
	firstSaleStart: z.number().optional(),
	firstSaleEnd: z.number().optional(),
	lastSaleStart: z.number().optional(),
	lastSaleEnd: z.number().optional(),
	signedUpStart: z.number().optional(),
	signedUpEnd: z.number().optional(),
	loyalty: z.number().optional(),
	emailOptin: z.number().optional(),
	pushOptin: z.number().optional(),
	browserOptin: z.number().optional(),
	smsOptin: z.number().optional(),
	voiceOptin: z.number().optional(),
	ageGate: z.number().optional(),
	isMedical: z.number().optional(),
	hasName: z.number().optional(),
	hasEmail: z.number().optional(),
	hasPhone: z.number().optional(),
	hasMobilePhone: z.number().optional(),
	hasHomePhone: z.number().optional(),
	hasAddress: z.number().optional(),
	hasStreet: z.number().optional(),
	hasCity: z.number().optional(),
	hasState: z.number().optional(),
	hasDOB: z.number().optional(),
	hasPurchase: z.number().optional(),
	hasUsedPoints: z.number().optional(),
	andLogic: z.boolean().optional(),
});

export const FullContactQuerySchema = z.object({
	initial: z.boolean().optional(),
	fullResponse: z.boolean().optional(),
	query: ContactQuerySchema.optional(),
	pagination: PaginationSchema,
	sort: SortFieldsSchema.optional(),
});

export const ContactQueryResponseSchema = FullContactQuerySchema.extend({
	contacts: z.array(ContactSchema),
});

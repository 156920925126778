import { z } from 'zod';

export const SurveyQuestionTypeSchema = z.enum([
	'input',
	'number',
	'textarea',
	'select',
	'multi-select',
	'radio-vertical',
	'radio',
	'checkbox',
	'toggle-vertical',
	'toggle',
	'slider',
	'rating',
	'date',
	'phone',
	'email',
]);

export const SurveyQuestionSchema = z.object({
	id: z.string(),
	label: z.string(),
	placeholder: z.string().optional(),
	value: z.union([z.string(), z.number(), z.boolean(), z.array(z.string())]),
	type: SurveyQuestionTypeSchema,
	required: z.boolean(),
	disabled: z.boolean().optional(),
	options: z.array(z.string()).optional(),
	style: z.record(z.any()).and(z.object({})).optional(),
	priority: z.number(),
	validator: z.string().optional(),
	validatorMessage: z.string().optional(),
});

export const SurveyAnswerValueTypeSchema = z.union([z.string(), z.number(), z.boolean(), z.array(z.string()), z.null(), z.undefined()]);

export const SurveyAnswerSchema = z.object({
	id: z.string(),
	questionID: z.string(),
	value: SurveyAnswerValueTypeSchema,
	type: SurveyQuestionTypeSchema,
	created: z.string().optional(),
});

export const SurveySubmissionSchema = z.object({
	uid: z.string().optional(),
	id: z.string().optional(),
	surveyID: z.string().optional(),
	phone: z.string().optional(),
	email: z.string().optional(),
	name: z.string().optional(),
	contactID: z.string().optional(),
	answers: z.array(SurveyAnswerSchema).optional(),
	created: z.number().optional(),
});

export const SurveySubmissionExtended = SurveySubmissionSchema.extend({
	surveyName: z.string().optional(),
	// Questions is a map of  ID -> Question
	questions: z.record(SurveyQuestionSchema).optional(),
});

export const SurveySchema = z.object({
	id: z.string(),
	uid: z.string(),
	name: z.string(),
	description: z.string(),
	redirectURL: z.string(),
	questions: z.array(SurveyQuestionSchema),
	allowAnon: z.boolean().optional(),
	archived: z.boolean(),
	startDate: z.string(),
	endDate: z.string(),
	locations: z.array(z.string()),
	created: z.string(),
	updated: z.string(),
});

import { z } from 'zod';

export const SignupFormStoreStatsSchema = z.object({
	storeID: z.number(),
	views: z.number(),
	signups: z.number(),
	firstView: z.date(),
	lastView: z.date(),
});

export const SignupFormViewStatsSchema = z.object({
	stores: z.array(SignupFormStoreStatsSchema),
	totalViews: z.number(),
	totalSignups: z.number(),
	conversionPercent: z.number(),
	firstView: z.date(),
	lastView: z.date(),
});
